<template>
  <base-material-card
    icon="mdi-map-marker"
    title="Address"
    class="address"
  >
    <v-form
      ref="importAddressForm"
      lazy-validation
      @submit.prevent="importAddress"
    >
      <v-row
        justify="center"
        align="center"
        class="mb-3"
      >
        <v-col
          cols="12"
          md="8"
        >
          <v-select
            v-model="importingAddressId"
            label="Select Address"
            prepend-icon="mdi-map-marker"
            :items="addressesToBeImported"
            item-value="id"
            :item-text="addressName"
            no-data-text="This policy holder doesn't have addresses."
            :rules="[required]"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-btn
            color="success"
            small
            type="submit"
            :loading="importing"
          >
            <v-icon left>
              mdi-import
            </v-icon>
            Import
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-expansion-panels v-if="policy.addresses && policy.addresses.length">
      <v-expansion-panel
        v-for="address in policy.addresses"
        :key="address.id"
        @change="reloadMap(address.id)"
      >
        <v-expansion-panel-header>
          {{ addressName(address) }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-form
            :ref="`addressForm${address.id}`"
            lazy-validation
            @submit.prevent="updateAddress(address)"
          >
            <v-container class="py-0">
              <l-map
                v-if="address.latitude && address.longitude"
                :ref="`map${address.id}`"
                class="map"
                :zoom="13"
                :center="[Number(address.latitude), Number(address.longitude)]"
                :options="{ dragging: false, scrollWheelZoom: false}"
              >
                <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                <l-marker :lat-lng="[Number(address.latitude), Number(address.longitude)]" />
              </l-map>

              <v-row>
                <v-col
                  v-for="(field, i) in fields"
                  :key="i"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-if="field.model === 'country'"
                    v-model="address[field.model]"
                    :prepend-icon="field.icon"
                    :items="mixinItems.countries"
                    item-text="name"
                    item-value="code"
                    :loading="loadingMixins.countries"
                    :label="field.label"
                    :rules="field.required ? [required] : []"
                  >
                    <template
                      v-if="address[field.model]"
                      v-slot:prepend
                    >
                      <flag
                        :iso="address[field.model]"
                        :squared="false"
                      />
                    </template>
                    <template
                      v-else
                      v-slot:prepend
                    >
                      <v-icon>mdi-flag</v-icon>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="address[field.model]"
                    :prepend-icon="field.icon"
                    :label="field.label"
                    :type="field.type"
                    :readonly="field.readonly"
                    :rules="field.required && !['latitude', 'longitude'].includes(field.model) ? [required] : []"
                  />
                </v-col>
              </v-row>
              <div class="pa-3 text-right">
                <v-btn
                  color="success"
                  type="submit"
                  :loading="updating"
                >
                  Update
                </v-btn>
                <v-btn
                  class="ml-3"
                  color="error"
                  :loading="deleting"
                  @click="deleteAddress(address)"
                >
                  Delete
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

  export default {
    components: {
      LMap, LTileLayer, LMarker,
    },

    mixins: [
      fetchInitials([
        MIXINS.countries,
      ]),
    ],

    props: {
      policy: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      fields: [
        { label: 'Street 1 *', model: 'street_1', icon: 'mdi-map-marker', required: true },
        { label: 'Street 2 *', model: 'street_2', icon: 'mdi-map-marker' },
        { label: 'State *', model: 'state', icon: 'mdi-map-marker', required: true },
        { label: 'City *', model: 'city', icon: 'mdi-map-marker', required: true },
        { label: 'Zip *', model: 'zip', icon: 'mdi-map-marker', required: true },
        { label: 'Country *', model: 'country', icon: 'mdi-flag', required: true },
        { label: 'Latitude', model: 'latitude', icon: 'mdi-latitude', required: true, readonly: true },
        { label: 'Longitude', model: 'longitude', icon: 'mdi-longitude', required: true, readonly: true },
      ],
      importingAddressId: null,
      importing: false,
      updating: false,
      deleting: false,
    }),

    computed: {
      addressesToBeImported () {
        if (this.policy) {
          return this.policy.insured_company_id ? this.policy.policy_company_address : this.policy.policy_user_address
        }

        return []
      },
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async importAddress () {
        if (this.$refs.importAddressForm.validate()) {
          this.importing = true
          try {
            const response = await axios.post('policies/import-address/' + this.$route.params.id, { address_id: this.importingAddressId })
            this.showSnackBar({ text: response.data.message, color: response.data.success ? 'success' : 'error' })
          } catch (error) {
            this.showSnackBar({ text: error, color: 'error' })
          }
          this.importing = false
          this.$emit('updateGeneral')
        }
      },

      async updateAddress (address) {
        if (this.$refs[`addressForm${address.id}`][0].validate()) {
          this.updating = true
          try {
            const response = await axios.put('customer/address/' + address.id, address)
            this.showSnackBar({ text: response.data.message, color: response.data.success ? 'success' : 'error' })
          } catch (error) {
            this.showSnackBar({ text: error, color: 'error' })
          }
          this.updating = false
          this.$emit('updateGeneral')
        }
      },

      async deleteAddress (address) {
        const decision = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
        if (decision) {
          this.deleting = true
          try {
            const response = await axios.delete('customer/address/' + address.id)
            this.showSnackBar({ text: response.data.message, color: response.data.success ? 'success' : 'error' })
          } catch (error) {
            this.showSnackBar({ text: error, color: 'error' })
          }
          this.deleting = false
          this.$emit('updateGeneral')
        }
      },

      addressName (address) {
        const texts = []
        for (const [key, value] of Object.entries(address)) {
          if (
            [
              'street_1',
              'city',
              'state',
              'zip',
            ].includes(key) && value
          ) {
            texts.push(value)
          }
        }
        return texts.join(', ')
      },

      reloadMap (id) {
        if (this.$refs[`map${id}`]) {
          setTimeout(() => this.$refs[`map${id}`][0].mapObject.invalidateSize(), 500)
        }
      },
    },
  }
</script>
